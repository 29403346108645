import { graphql } from "gatsby";
import * as React from "react";
import styled from "styled-components";
import { unwrapFirst } from "../util";
import {
  Layout,
  HelmetSeo,
  Hero,
  HeroCoverBackground,
  Section,
  Cols,
  Col,
  HorizontalContainer,
  Button,
  FormattedRichText,
} from "../components/legacy";
import HERO_BACKGROUND_1 from "../../img/ui/patterns/hero_background_1.svg";
import { Color } from "../constants/legacy";

type Props = {
  data: GatsbyTypes.ComparisonDetailPageQuery;
};

export const query = graphql`
  query ComparisonDetailPage {
    prismicComparisonDetailPage {
      data {
        hero_title
        hero_sub_title {
          raw
        }

        comparison_heading
        shipamax_pros {
          shipamax_pro
        }
        traditional_cons {
          traditional_con
        }
        comparison_button_label

        steps_heading
        steps_description
        steps {
          step_description
        }

        rows {
          row_title
          row_description
          row_text_traditional {
            raw
          }
          row_text_shipamax {
            raw
          }
        }

        cta_heading
        cta_detail_text
        cta_button_label

        seo_title
        seo_description
        share_image {
          alt
          dimensions {
            width
            height
          }
          url
        }
      }
    }
  }
`;

export default function ComparisonDetailPage({ data }: Props) {
  const page = data.prismicComparisonDetailPage?.data;
  if (!page) throw new Error("page missing");

  return (
    <Layout>
      <HelmetSeo {...page} />

      <Hero
        title={page.hero_title || ""}
        subTitle={page.hero_sub_title?.raw}
        customBackground={<HeroCoverBackground src={HERO_BACKGROUND_1} />}
      />

      <Section>
        <HorizontalContainer>
          <h2
            style={{
              textAlign: "center",
              maxWidth: 740,
              margin: "0 auto 55px",
            }}
          >
            {page.comparison_heading}
          </h2>

          <Cols>
            <Col flex={5}>
              <StyledComparisonBox $pro>
                <h3>Document Processing with Shipamax</h3>
                {page.shipamax_pros?.map((item) =>
                  !item ? null : (
                    <div key={item.shipamax_pro}>{item.shipamax_pro}</div>
                  )
                )}
              </StyledComparisonBox>
            </Col>
            <Col flex={1} />
            <Col flex={5}>
              <StyledComparisonBox $pro={false}>
                <h3>Document Processing with Traditional OCR</h3>
                {page.traditional_cons?.map((item) =>
                  !item ? null : (
                    <div key={item.traditional_con}>{item.traditional_con}</div>
                  )
                )}
              </StyledComparisonBox>
            </Col>
          </Cols>

          <StyledButtonWrap>
            <Button
              label={page.comparison_button_label || "Get a demo"}
              color="orange"
              to="/contact"
            />
          </StyledButtonWrap>
        </HorizontalContainer>
      </Section>

      <StyledSectionSteps>
        <HorizontalContainer>
          <h2>{page.steps_heading}</h2>

          <div className="description-wrap">{page.steps_description}</div>

          <StyledSteps>
            {page.steps?.map((step, i) =>
              !step ? null : (
                <div key={i}>
                  <h3>Step {i + 1}</h3>
                  <p>{step.step_description}</p>
                </div>
              )
            )}
          </StyledSteps>
        </HorizontalContainer>
      </StyledSectionSteps>

      <StyledTableScroll>
        <Section>
          <HorizontalContainer>
            <StyledTable cellPadding={0} cellSpacing={0}>
              <thead>
                <tr>
                  <th className="empty" />
                  <th>Traditional Template OCR</th>
                  <th>Shipamax</th>
                </tr>
              </thead>
              <tbody>
                {page.rows?.map((row, i) =>
                  !row ? null : (
                    <tr key={row.row_title}>
                      <td className="title">
                        <h3>{row.row_title}</h3>
                        <div>{row.row_description}</div>
                      </td>
                      <td>
                        <FormattedRichText
                          fontSize={12}
                          render={row.row_text_traditional?.raw}
                        />
                      </td>
                      <td className={i >= 1 ? "dark" : undefined}>
                        <FormattedRichText
                          fontSize={12}
                          onDark
                          render={row.row_text_shipamax?.raw}
                        />
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </StyledTable>
          </HorizontalContainer>
        </Section>
      </StyledTableScroll>
    </Layout>
  );
}

const StyledComparisonBox = styled("div")<{ $pro: boolean }>`
  padding: 35px;
  background-color: #f6f8fa;

  > h3 {
    font-size: 16px;
  }

  > div {
    padding-left: 30px;
    margin-bottom: 15px;
    font-size: 14px;
    position: relative;

    &::before {
      content: "${(p) => (p.$pro ? "✅" : "❌")}";
      position: absolute;
      left: 0;
    }
  }

  @media (max-width: 600px) {
    padding: 20px;
  }
`;

const StyledButtonWrap = styled.div`
  margin: 60px auto 0;
  max-width: 200px;
`;

const StyledSectionSteps = styled(Section)`
  background-color: ${Color.darkBlue};
  color: #fff;

  h2 {
    text-align: center;
  }

  h2,
  h3 {
    color: ${Color.cyan};
  }

  .description-wrap {
    max-width: 640px;
    text-align: center;
    margin: 0 auto;
  }
`;

const StyledSteps = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 55px;
  margin-right: -3%;

  > div {
    background-color: ${Color.blue};
    padding: 20px;
    width: 47%;
    margin-right: 3%;
    margin-bottom: 25px;

    &:last-of-type {
      width: 97%;
      margin-bottom: 0;
    }

    h3 {
      margin-bottom: 10px;
    }

    @media (max-width: 600px) {
      width: 100%;
      margin-bottom: 20px;

      &:last-of-type {
        width: 100%;
      }
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    margin-right: 0;
  }
`;

const StyledTableScroll = styled.div`
  overflow-x: auto;
`;

const StyledTable = styled.table`
  color: #000000;

  th,
  td {
    background-color: ${Color.white};
    padding: 15px 18px;
    border: #828282 solid;
    border-width: 0 1px 1px 0;

    &:first-child {
      border-left-width: 1px;
    }
  }

  th {
    font-size: 14px;
    text-align: center;
    color: ${Color.white};
    background-color: ${Color.blue};
    border-top-width: 1px;

    &.empty {
      background-color: ${Color.lightGrey};
    }
  }

  td {
    background-color: ${Color.white};
    font-size: 12px;
    width: 35%;
    min-width: 250px;
    vertical-align: top;

    &.dark {
      background-color: ${Color.darkBlue};
      color: ${Color.white};
    }

    &.title {
      background-color: #eaeaea33;
      text-align: left;
      width: 30%;

      h3 {
        font-size: 18px;
        font-weight: 600;
        margin: 0 0 15px;
      }

      div {
        font-size: 14px;
      }
    }
  }
`;
